import axios from 'axios';
import config from '../config/config';
import {retryableRequestError, getRetryDelay, onRetry} from '../helpers/helpers';
// const axiosRetry = require("axios-retry");
import axiosRetry from 'axios-retry';



const sherpaAPI = axios.create({
   baseURL: config.baseURL,
   timeout: 30000
})

const lifesherpaAuth = axios.create({
   baseURL: config.baseURL,
   timeout: 30000
})

const lifesherpaAuthBaseAPI = axios.create({
   baseURL: config.authBaseURL
})

const authAPI = axios.create({
   baseURL: config.baseURLForToken
})

axiosRetry(sherpaAPI, {
   retries: 3,
   retryCondition: retryableRequestError,
   retryDelay: getRetryDelay,
   shouldResetTimeout: true,
   onRetry: onRetry
});

axiosRetry(lifesherpaAuth, {
   retries: 3,
   retryCondition: retryableRequestError ,
   retryDelay: getRetryDelay,
   shouldResetTimeout: true,
   onRetry: onRetry
});


axiosRetry(lifesherpaAuthBaseAPI, {
   retries: 3,
   retryCondition: retryableRequestError,
   retryDelay: getRetryDelay,
   onRetry: onRetry
});

axiosRetry(authAPI, {
   retries: 3,
   retryCondition: retryableRequestError,
   retryDelay: getRetryDelay,
   onRetry: onRetry
});


const locationAPI = axios.create({
   baseURL: "https://ipapi.co/json/",
   timeout: 30000
})

const geoCoding = axios.create({
   baseURL: "https://maps.googleapis.com/maps/api/geocode"
})


const userProfileAPI = axios.create({
   baseURL: 'https://en.gravatar.com/',
   timeout: 30000
})

export {
   sherpaAPI,
   lifesherpaAuth,
   lifesherpaAuthBaseAPI,
   authAPI,
   locationAPI,
   geoCoding,
   userProfileAPI,
}
