import React from 'react';

// Use React.lazy for dynamic imports
const AsyncAppSignIn = React.lazy(() => import("../../container/SigninFirebase"));
const AsyncAppSignUpLifesherpa = React.lazy(() => import("../../container/LifesherpaSignup"));
const AsyncAppLoginSuccess = React.lazy(() => import("../../components/LoginComponent/LoginSuccess"));
const AsyncAppAuth0LoginSuccess = React.lazy(() => import("../../components/LoginComponent/Auth0LoginSuccess"));
const AsyncAppReactCharts = React.lazy(() => import("../../components/Charts"));
const AsyncMyActivityPreview = React.lazy(() => import("Components/MyActivityPreview"));

export {
  AsyncAppSignIn,
  AsyncAppSignUpLifesherpa,
  AsyncAppLoginSuccess,
  AsyncAppAuth0LoginSuccess,
  AsyncAppReactCharts,
  AsyncMyActivityPreview,
};
