/**
 * Helpers Functions
 */
import moment from 'moment';
import { RRule, RRuleSet, rrulestr } from 'rrule'
import cronstrue from 'cronstrue';
const axiosRetry = require("axios-retry");
import { sherpaAPI } from '../sherpaApi';
import config from '../config/config';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    return path[1];
}

/**
 * Function to return rrule
 */
export function  generateRRule(recurrenceTextStr, rrulroptions, currentSchedule) {
    const recurrenceText  = getRepeatOption(recurrenceTextStr, currentSchedule)
    const date = currentSchedule.startDate
    const time = moment(currentSchedule.objStartTime.toISOString()).format('HH:mm').toString()
    const startTime = moment(currentSchedule.objStartTime.toISOString()).format('hh:mm A').toString()
    const startDate = moment(new Date(date)).format('MMM DD YYYY')
    const dateStr = getDateAndTime(date, time)
    const weekday = getWeekDay(currentSchedule.startDate)
    const weekDayStart = weekday.substring(0, 2).toUpperCase()
    const rruleDic = {
        'rrule': '',
        'recurrenceMethod': '',
        'text': '',
        "nextSchedules": []
    }

    switch(recurrenceText) {
        case "Does not repeat":
            // code block
            // Create a rule:
            const norepeatRRule = new RRule({
                freq: RRule.DAILY,
                wkst: RRule.SU,
                interval: 1,
                count: 1,
                dtstart: new Date(dateStr),
                byweekday: [RRule[weekDayStart]],
            })
            rruleDic.rrule = norepeatRRule.toString()
            rruleDic.recurrenceMethod = 'norepeat'
            rruleDic.text = `Once at ${startTime} start on ${startDate}`
            break;
        case "Daily":
            // Create a rule:
            const dailyRRule = new RRule({
                freq: RRule.DAILY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                dtstart: new Date(dateStr),
            })
            rruleDic.rrule = dailyRRule.toString()
            rruleDic.recurrenceMethod = 'days'
            rruleDic.text = `Daily until forever at ${startTime} start on ${startDate}`
            break;
        case `Monthly on the ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`:
            // Create a rule:
            const occurences = getDayOccurences(currentSchedule.startDate)
            const dayOfMonthRRule = new RRule({
                freq: RRule.MONTHLY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                dtstart: new Date(dateStr),
                bysetpos: occurences === 'First' ? 1 : occurences === 'Second' ? 2 : occurences === 'Third' ? 3 : occurences === 'Fourth' ? 4 : 5,
                byweekday: [RRule[weekDayStart]],
            })
            rruleDic.rrule = dayOfMonthRRule.toString()
            rruleDic.recurrenceMethod = 'dayOfMonth'
            rruleDic.text = `Monthly on the ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)} until forever at ${startTime} start on ${startDate}`
            break;
        case `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`:
            // Create a rule:
            const monthDate = getDateOfMonth(currentSchedule.startDate)
            const dateOfMonthRRule = new RRule({
                freq: RRule.MONTHLY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                dtstart: new Date(dateStr),
                bymonthday: monthDate,
            })
            rruleDic.rrule = dateOfMonthRRule.toString()
            rruleDic.recurrenceMethod = 'dateOfMonth'
            rruleDic.text = `Monthly on day ${getDateOfMonth(currentSchedule.startDate)} until forever at ${startTime} start on ${startDate}`
            break;
        case `Every ${getMonthName(currentSchedule.startDate)} on ${getDateOfMonth(currentSchedule.startDate)} (Annually)`:
            // Create a rule:
            const mDay = getMonthOfYear(currentSchedule.startDate)
            const mdate = getDateOfMonth(currentSchedule.startDate)
            const yearlyRRule = new RRule({
                freq: RRule.YEARLY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                dtstart: new Date(dateStr),
                bymonth: mDay,
                bymonthday: mdate,
            })
            rruleDic.rrule = yearlyRRule.toString()
            rruleDic.recurrenceMethod = 'years'
            rruleDic.text = `Every ${getMonthName(currentSchedule.startDate)} on the ${mdate}th (Annually) until forever at ${startTime} start on ${startDate}`
            break;
        case `Every day on ${getWeekDay(currentSchedule.startDate)}`:
            // Create a rule:
            const weekdayRRule = new RRule({
                freq: RRule.DAILY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                dtstart: new Date(dateStr),
                byweekday: [RRule[weekDayStart]],
            })
            rruleDic.rrule = weekdayRRule.toString()
            rruleDic.recurrenceMethod = 'weekday'
            rruleDic.text = `Every day on ${getWeekDay(currentSchedule.startDate)} until forever at ${startTime} start on ${startDate}`
            break;
        case 'Every Weekday (Monday to Friday)':
            // Create a rule:
            const weeksRRule = new RRule({
                freq: RRule.WEEKLY,
                wkst: RRule.SU,
                interval: 1,
                // count: 1,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
                dtstart: new Date(dateStr),
            })
            rruleDic.rrule = weeksRRule.toString()
            rruleDic.recurrenceMethod = 'weeks'
            rruleDic.text = `Every Weekday (Monday to Friday) until forever at ${startTime} start on ${startDate}`
            break;
        case 'Custom':
           // Create a rule:
            const customRRule = new RRule({
                freq: RRule.DAILY,
                wkst: RRule.SU,
                interval: 1,
                dtstart: new Date(dateStr),
            });
            rruleDic.rrule = customRRule.toString()
            rruleDic.recurrenceMethod = 'custom';
            currentSchedule['rrule'] = rruleDic.rrule
            rruleDic.text = getCronExpressionText(currentSchedule);// `${customRRule.toText()} until forever at ${startTime} start on ${startDate}`;
            if (customRRule.toText().includes('until')){
                rruleDic.text = `${rruleDic.text} start on ${startDate}`
            } else {
                rruleDic.text = `${rruleDic.text} until forever start on ${startDate}`
            }
            break;
        default:
            let options = rrulroptions
            options['dtstart'] = new Date(dateStr)
            const defaultRRule = new RRule(options)
            const rruleText = defaultRRule.toText()
            rruleDic.rrule = defaultRRule.toString()
            rruleDic.recurrenceMethod = 'custom';
            currentSchedule['rrule'] = rruleDic.rrule
            rruleDic.text = getCronExpressionText(currentSchedule);
            if (rruleText.includes('until')){
                rruleDic.text = `${rruleDic.text} start on ${startDate}`
            } else {
                rruleDic.text = `${rruleDic.text} until forever start on ${startDate}`
            }
            console.log("rrule text :", defaultRRule.toText());
            console.log("cron text: ", rruleDic.text);
      }

      return rruleDic
   }

   /**
     * Function to return day occurence
     */
    export function getDayOccurences(date) {
        // const dateIn2digit  = moment(date).format('D')
        const dateIn2digit = getDateOfMonth(date);
        var quotient = Math.floor(dateIn2digit/7)
        var remainder = dateIn2digit % 7;
        if (remainder !== 0){
            if (quotient === 0) {
                return 'First'
            } else if (quotient === 1) {
                return 'Second'
            } else if (quotient === 2) {
                return 'Third'
            } else if (quotient === 3) {
                return 'Fourth'
            }else if (quotient === 4) {
                return 'Fifth'
            }
        } else {
            if (quotient === 1) {
                return 'First'
            } else if (quotient === 2) {
                return 'Second'
            } else if (quotient === 3) {
                return 'Third'
            } else if (quotient === 4) {
                return 'Fourth'
            } else if (quotient === 5) {
                return 'Fifth'
            }
        } 
    }
    /**
     * Function to return Month occurence
     */
    export function getMonthOccurence(currentSchedule, option){
        if (option.split('=')[0] === 'BYSETPOS') {
            return `Monthly on ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`
        } else if (option.split('=')[0] === 'BYMONTHDAY' && option.split('=')[1] === '-1') {
            return 'Last Day of Month'
        } else {
            return `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`
        }
    }

    /**
     * Function to return Week occurence
     */
    export function parseCustomWeekDay(weekDay, currentValue){
        let weekDayWithCheck = currentValue
        weekDayWithCheck[0].checked = false
        const days = weekDay.split('=')[1].split(',')
        days.map((day, key) => {
            if (day === 'MO') {
                weekDayWithCheck[0].checked = true
            } else if (day === 'TU') {
                weekDayWithCheck[1].checked = true
            } else if (day === 'WE') {
                weekDayWithCheck[2].checked = true
            } else if (day === 'TH') {
                weekDayWithCheck[3].checked = true
            } else if (day === 'FR') {
                weekDayWithCheck[4].checked = true
            } else if (day === 'SA') {
                weekDayWithCheck[5].checked = true
            } else if (day === 'SU') {
                weekDayWithCheck[6].checked = true
            }
        })
        return weekDayWithCheck
    }

     /**
     * Function to return freq occurence
     */
    export function parseCustomFreq(freq){
        if(freq.split('=')[1] === 'DAILY'){
            return {freq: 'Day', weekSelected: false, monthSelected: false}
        } else if(freq.split('=')[1] === 'WEEKLY') {
            return {freq: 'Week', weekSelected: true, monthSelected: false}
        } else if(freq.split('=')[1] === 'MONTHLY') {
            return {freq: 'Month', weekSelected: false, monthSelected: true}
        } else {
            return {freq: 'Year', weekSelected: false, monthSelected: false}
        }
    }

    /**
     * Function to return fresh schedule object
     */
    export function refreshCurrentSchedule(){
        const currentSchedule =  {
            startDate: moment().format('MMM DD YYYY'),
            startTime: moment().format('hh:mm A'),
            endTime: moment().format('hh:mm A'),
            text: "Daily",
            recurrenceMethod: '',
            rrule: '',
            objStartDate: moment(),
            objStartTime: moment(),
            objEndTime: moment().add(5, 'minutes'),
            nextSchedules: []
        }
        const rruleDic =  generateRRule('Daily', null, currentSchedule)
        currentSchedule['rrule'] = rruleDic.rrule
        currentSchedule['text'] = rruleDic.text
        currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
        return currentSchedule
     }

    /**
     * Function to return fresh schedule object
     */
    export function handleCustomSchedule(repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, currentSchedule, invalidEndTime, classRef) {
        // let currentSchedule = currentSchedule
        const monthDate = getDateOfMonth(currentSchedule.startDate)
        const occurences = getDayOccurences(currentSchedule.startDate)
        const monthDay = getMonthOfYear(currentSchedule.startDate)
        const weekDayStart = getWeekDay(currentSchedule.startDate).substring(0, 2).toUpperCase()
        let options = {}
        let ends = repeatType.filter(item => item.selected)
        if(ends[0].type === 'On' && ends[0].selected) {
            options['until'] = new Date(customUntill) //.toISOString()
        } else if (ends[0].type === 'After' && ends[0].selected) {
            options['count'] = customCount
        }
        if (customRepeatValue === 'Week') {
            options['freq'] =  RRule.WEEKLY 
            options['wkst'] =  RRule.SU 
            options['interval'] = customInterval
            let byweekday =  []
            weekDayWithCheck.map((item, key) => {
                if (item.checked) {
                    byweekday.push(RRule[item.day])
                }
            })
            options['byweekday'] = byweekday
            const rruleDic =  generateRRule('CustomWeek', options, currentSchedule)
            currentSchedule['text'] = getCustomWeekString(rruleDic.text, customInterval);
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
            classRef.setState({weekSelected: true, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, invalidEndTime})
        } else if (customRepeatValue === 'Month') {
            options['freq'] =  RRule.MONTHLY 
            options['wkst'] =  RRule.SU 
            options['interval'] = customInterval
            if (monthlyOccurenceDay === '' || monthlyOccurenceDay === `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`) {
                options['bymonthday'] = monthDate
            } else if (monthlyOccurenceDay === `Monthly on ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`) {
                options['bysetpos'] = occurences === 'First' ? 1 : occurences === 'Second' ? 2 : occurences === 'Third' ? 3 : occurences === 'Fourth' ? 4 : 5
                options['byweekday'] = [RRule[weekDayStart]]
            } else {
                options['bymonthday'] = -1
            }
            const rruleDic =  generateRRule('CustomMonth', options, currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
            classRef.setState({weekSelected: false, monthSelected: true, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, invalidEndTime})
         } else if (customRepeatValue === 'Year') {
            options['freq'] =  RRule.YEARLY 
            options['wkst'] =  RRule.SU 
            options['interval'] = 1
            options['bymonth']= monthDay
            options['bymonthday'] = monthDate
            const rruleDic =  generateRRule('CustomYear', options, currentSchedule)
            currentSchedule['text'] = rruleDic.text
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
            classRef.setState({weekSelected: false, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval: 1, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, invalidEndTime})
        } else {
            options['freq'] =  RRule.DAILY 
            options['wkst'] =  RRule.SU 
            options['interval'] = customInterval
            const rruleDic =  generateRRule('CustomDay', options, currentSchedule)
            currentSchedule['text'] = rruleDic.text 
            currentSchedule['rrule'] = rruleDic.rrule
            currentSchedule['recurrenceMethod'] = rruleDic.recurrenceMethod
            currentSchedule['nextSchedules'] = rruleDic.nextSchedules
            classRef.setState({weekSelected: false, monthSelected: false, currentSchedule, repeatType, customRepeatValue, customCount, customInterval, customUntill, weekDayWithCheck, monthlyOccurenceDay, repeatError, customCountError, invalidEndTime})
         }
    }

    function getCustomWeekString(text, customInterval) {
        let option = text;
        if(customInterval > 1){
            const numToWords = ['zero', 'first', 'second', 'third', 'fourth', 'fifth'];
            console.log('customRepeatValue period is Week', text);
            option = option + `, every ${numToWords[customInterval]} week`;
        } 
        return option;
    }

    export function getCronExpressionText(currentSchedule, customRepeatValue, customInterval) {
        const scheduleObj = {...currentSchedule};
         scheduleObj.dt = (scheduleObj.startDate ? new Date(scheduleObj.startDate) : new Date());
         scheduleObj.startDate = (scheduleObj.startDate ? new Date(scheduleObj.startDate) : new Date());
         scheduleObj.startTime = (scheduleObj.objStartTime ? new Date(scheduleObj.objStartTime) : new Date());
         scheduleObj.endTime = (scheduleObj.objEndTime ? new Date(scheduleObj.objEndTime) : new Date());
         const cronExpression = generateCronFromRRule(scheduleObj, false);
         const stringSchedule = cronstrue.toString(cronExpression);
         return stringSchedule;

    }

    /**
     * Function to return name of week day 
     */
    export function getWeekDay(date){
        const gsDayNames = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
        const dateObj = new Date(date)
        return gsDayNames[dateObj.getDay()]
    }

    /**
     * Function to return date of month in digit 
     */
    export function getDateOfMonth(date){
        const dateObj = new Date(date)
        return dateObj.getDate();
    }

    /**
     * Function to return Name of month of Year
     */
    export function getMonthName(date) {
        const monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"]
        const dateObj = new Date(date)
        return monthNames[dateObj.getMonth()]
    }

    /**
     * Function to return Name of month of Year
     */
    export function getMonthOfYear(date) {
        const dateObj = new Date(date)
        const monthNumber = dateObj.getMonth() + 1
        return monthNumber
    }

    /**
     * Function to return date and time
     */
    export function getDateAndTime(date, time) {
        var dateObj = new Date(date)
        const hours = parseInt(time.split(":")[0])
        const minutes = parseInt(time.split(":")[1])
        dateObj.setHours(hours)
        dateObj.setMinutes(minutes)
        return dateObj
    }

    /**
     * Function to return fresh week day
     */
    export function getweekDayWithCheck() {
        const weekDay =  [
            {
                day: 'MO',
                checked: true,
                name: 'MON'
            },
            {
                day: 'TU',
                checked: false,
                name: 'TUE'
            },
            {
                day: 'WE',
                checked: false,
                name: 'WED'
            },
            {
                day: 'TH',
                checked: false,
                name: 'THU'
            },
            {
                day: 'FR',
                checked: false,
                name: 'FRI'
            },
            {
                day: 'SA',
                checked: false,
                name: 'SAT'
            },
            {
                day: 'SU',
                checked: false,
                name: 'SUN'
            },
        ]
        return weekDay
    }

    /**
     * Function to return fresh week day
     */
    export function getISODateString(date) {
       return date ? new Date(date).toISOString() : new Date().toISOString()
    }

     /**
     * Function to return cookies
     */
    export function getCookies(name) {
        // const data = {"userId":"userId-value","idToken":"idToken-value","orgId":"orgId-value","activityId":"activityId-value","theme":"theme-value", "language": "language-value"}
        // document.cookie = `objCookieData=${JSON.stringify(data)}`
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      }

    /**
     * Function to return truncated string
     */
    export function ellipsify (str) {
        let newstring = JSON.parse(JSON.stringify(str))
        if (newstring.length > 80) {
            return (newstring.substring(0, 80) + "...");
        }
        else {
            return newstring;
        }
    }

    export function getRepeatOption(text, currentSchedule){
        let option = text
         if (currentSchedule.recurrenceMethod === 'custom') {
             option = text
         } else if(text.includes('Does not repeat')) {
             option = 'Does not repeat'
         } else if(text.includes('Daily')) {
             option = 'Daily'
         } else if(text.includes('Monthly on day')) {
             option = `Monthly on day ${getDateOfMonth(currentSchedule.startDate)}`
         } else if(text.includes('Monthly on')) {
             option = `Monthly on the ${getDayOccurences(currentSchedule.startDate)} ${getWeekDay(currentSchedule.startDate)}`
         } else if(text.includes('Every day on') || text.includes('Every Day on')) {
             option =  `Every day on ${getWeekDay(currentSchedule.startDate)}`
         } else if(text.includes('Every Weekday')) {
             option =  'Every Weekday (Monday to Friday)'
         } else if(text.includes('Every')) {
             option =  `Every ${getMonthName(currentSchedule.startDate)} on ${getDateOfMonth(currentSchedule.startDate)} (Annually)`
         }
         return option
     }

     export function checkEnterpriseConnection(setting, orgId){
         let connectionString = null
         const publicSettings = setting.publicSettings
         if (publicSettings && Object.keys(publicSettings).length > 0) {
            if (publicSettings.enterpriseConnections) {
                const enterpriseConnections = publicSettings.enterpriseConnections
                const connectionKeys = Object.keys(enterpriseConnections)
                connectionKeys.map(enterprise => {
                    if (enterpriseConnections[enterprise][orgId]){
                        connectionString = enterprise
                    }
                })
            } else {
                connectionString = null
            }
         } 
         return connectionString
     }
    export function encodeParams(params) {
        Object.entries(params).map(kv => kv.map(encodeURIComponent).join("=")).join("&");
    }

    //Get all availables connections for organization
    export function getAvailableConnections(setting, orgId){
        let availableConnections = []
        const publicSettings = setting.publicSettings
        if (publicSettings && Object.keys(publicSettings).length > 0) {
           if (publicSettings.enterpriseConnections) {
               const enterpriseConnections = publicSettings.enterpriseConnections
               const connectionKeys = Object.keys(enterpriseConnections)
               connectionKeys.map(enterprise => {
                   if (enterpriseConnections[enterprise][orgId]){
                        availableConnections.push(enterprise)
                   }
               })
           }
        } 
        return availableConnections
    }

    export function generateCronFromRRule(scheduleObj, offset){
        //NOTE: For Easy Cron week Days supported as 0 - 6 (representing Sun - Sat)
        let startTimeArr = [];
        const sHour = ( scheduleObj.startTime ? scheduleObj.startTime.getHours() : 0);
        const sMinute = ( scheduleObj.startTime ? scheduleObj.startTime.getMinutes() : 0);
        const startTimeString  = ( sHour < 10 ? ('0'+sHour) : sHour ) + ":" + ( sMinute < 10 ? ( '0'+sMinute) : sMinute)
        startTimeArr = startTimeString.split(":");
        let minute = startTimeArr[1];
        let hour = startTimeArr[0];
        const parsedDate = getUTCbyOffset(scheduleObj.dt, hour, minute, offset);
        const startHour = parsedDate.hour;
        const startMinute = parsedDate.minute;
        const intervalDaysStart = parsedDate.date;
        const intervalMonthStart = parsedDate.month;
        const rruleOriginalOptions = rrulestr(scheduleObj.rrule).origOptions;
    
        let dateOfMonth = new Date(rruleOriginalOptions.dtstart).getDate();
        let month = new Date(rruleOriginalOptions.dtstart).getUTCMonth();
        let cronExp = "";
        const dayOfMonth = getDayMonthCron(new Date(rruleOriginalOptions.dtstart));
    
        const interval = rruleOriginalOptions.interval || 1;
        //Yeraly
        if( rruleOriginalOptions.freq === 0 || rruleOriginalOptions.freq === '0' ){
            cronExp =  startMinute + " " + startHour + " "  + dateOfMonth + " " + (parseInt(month) + 1) + " *";
        }
        //Weekly
        if( rruleOriginalOptions.freq === 2 || rruleOriginalOptions.freq === '2' ){
            cronExp =  startMinute + " " + startHour + " * * " + getWeekDaysFromRule(rruleOriginalOptions.byweekday);
        }
        //Monthly
        if( rruleOriginalOptions.freq === 1 || rruleOriginalOptions.freq === '1' ){
            if(typeof rruleOriginalOptions.bymonthday !== 'undefined'){
                if( rruleOriginalOptions.bymonthday === -1 || rruleOriginalOptions.bymonthday === '-1'){
                    cronExp =  startMinute + " " + startHour + " L */" + interval + " *";
                    if( interval > 1){
                        cronExp =  startMinute + " " + startHour + " L " + intervalMonthStart + "/" + interval + " *";
                    }
                }else{
                    cronExp =  startMinute + " " + startHour + " "  + rruleOriginalOptions.bymonthday + " */" + interval + " *";
                    if( interval > 1){
                        cronExp =  startMinute + " " + startHour + " "  + rruleOriginalOptions.bymonthday + " " + intervalMonthStart + "/" + interval + " *";
                    }
                }
            }else if(typeof rruleOriginalOptions.byweekday !== 'undefined'){
                cronExp =  startMinute + " " + startHour + " * */" + interval + " " + dayOfMonth.dayNo + "#" + dayOfMonth.nthDay;
                if(interval > 1 ){
                    cronExp =  startMinute + " " + startHour + " * " + intervalMonthStart + "/" + interval + " " + dayOfMonth.dayNo + "#" + dayOfMonth.nthDay;
                }
            }else{
                if(interval > 1){
                    cronExp =  startMinute + " " + startHour + " "  + dateOfMonth + " " + intervalMonthStart + "/" +interval+ " *";
                }else{
                    cronExp =  startMinute + " " + startHour + " "  + dateOfMonth + " * *";
                }
            }
        }
    
        //Daily
        if( rruleOriginalOptions.freq === 3 || rruleOriginalOptions.freq === '3' ){
            if(typeof rruleOriginalOptions.byweekday !== 'undefined' ){
                if(interval > 1){
                    cronExp =  startMinute + " " + startHour + " " + intervalDaysStart + "/" + interval + " * " + getWeekDaysFromRule(rruleOriginalOptions.byweekday) ;
                }else{
                    cronExp =  startMinute + " " + startHour + " * * " + getWeekDaysFromRule(rruleOriginalOptions.byweekday);
                }
            }else{
                if(interval > 1){
                    cronExp =  startMinute + " " + startHour + " " + intervalDaysStart + "/" + interval + " * *";
                }else{
                    cronExp =  startMinute + " " + startHour + " * * *";
                }
            }
        }
        // console.log('Generated Cron Expression:',cronExp);
        return cronExp;
    }

    export function getUTCbyOffset(startDate, startHour, startMinute, offset){
        // const dt = moment().utcOffset(offset);
        const dt = moment(startDate);
        dt.set({hour:startHour, minute:startMinute});
        const result =  {
            std:startDate,
            hour: dt.hour(),
            minute: dt.minute(),
            date: dt.date(),
            month: parseInt(dt.utc().month()) + 1,  //jan is 0
        }
        return result;
    }

    export function getDayMonthCron( selectedDate){
        const dayOfMonth = {
            dayNo: selectedDate.getDay(),
            nthDay: Math.ceil(selectedDate.getDate() / 7) //2nd or 3rd sunday
        };
        return dayOfMonth;
    }

    function getWeekDaysFromRule( weekDays ){
        let daystring =[];
        for(const day in weekDays){
            let dayNo = parseInt(weekDays[day].weekday) + 1 ; //monday is 1 in easy cron
            if(dayNo === 7){
                dayNo = 0;
            }
            daystring.push( dayNo );
        }
        return daystring.join();
    }

    export function getCustomeRecurrenceFromRrule(schedule, classRef) {
        let customInterval = 1;
        let monthlyOccurenceDay = '';
        let customCount = 1;
        let customUntill = moment().add(1, 'd').format('MMM DD YYYY');
        let customRepeatValue = "Day";
        let weekSelected = false;
        let monthSelected = false;
        let weekDayWithCheck = [
            {
                day: 'MO',
                checked: true,
                name: 'MON'
            },
            {
                day: 'TU',
                checked: false,
                name: 'TUE'
            },
            {
                day: 'WE',
                checked: false,
                name: 'WED'
            },
            {
                day: 'TH',
                checked: false,
                name: 'THU'
            },
            {
                day: 'FR',
                checked: false,
                name: 'FRI'
            },
            {
                day: 'SA',
                checked: false,
                name: 'SAT'
            },
            {
                day: 'SU',
                checked: false,
                name: 'SUN'
            },
        ];
        let repeatType = [
            {
                type: 'Never',
                selected: true,
                value: null
            },
            {
                type: 'After',
                selected: false,
                value: null
            },
            {
                type: 'On',
                selected: false,
                value: null
            }
        ];
        const options = rrulestr(schedule.rrule).options;
        if(options.interval) {
            customInterval = options.interval;
        }
        console.log("Custom options : ", options);
        if(options.count && typeof options.count !== 'undefined'){
            repeatType[1].selected = true;
            customCount = options.count;
        } else if( options.until && typeof options.until !== 'undefined' ){
            repeatType[2].selected = true;
            customUntill = moment(new Date(options.until));
        } 
        const freq = options.freq;
        // Weekly
        if( freq === 2 || freq === '2' ){
            const byweekday = options.byweekday;
            if(byweekday) {
                for(const day in byweekday){
                    weekDayWithCheck[byweekday[day]].checked = true;
                }
            }
            customRepeatValue = "Week";
            weekSelected = true;
            monthSelected = false;
        }
        // Monthly
        if(freq === 1 || freq === '1') {
            monthlyOccurenceDay = getMonthlyOccurence(options, schedule);
            customRepeatValue = "Month";
            weekSelected = false;
            monthSelected = true;
        }
        //Yeraly
        if( freq === 0 || freq === '0' ){
            customRepeatValue = "Year";
            weekSelected = false;
            monthSelected = false;
        }
        classRef.setState({weekSelected, monthSelected, customRepeatValue, monthlyOccurenceDay, customUntill, customInterval, weekDayWithCheck, repeatType, customCount})
    }

     /**
     * Function to return Month occurence
     */
      export function getMonthlyOccurence(options, schedule){
        let monthlyOccurenceDay = `Monthly on day ${getDateOfMonth(schedule.startDate)}`;
        if(options.byweekday && typeof options.byweekday !== 'undefined' && options.byweekday.length){
            console.log('Inside dayOfMonth');
            monthlyOccurenceDay = `Monthly on ${getDayOccurences(schedule.startDate)} ${getWeekDay(schedule.startDate)}`
        }

        if(options.bymonthday && typeof options.bymonthday !== 'undefined' && options.bymonthday.length){
            console.log('Inside dateOfMonth');
            monthlyOccurenceDay = `Monthly on day ${getDateOfMonth(schedule.startDate)}`;
        }
        
        if(options.bynmonthday && typeof options.bynmonthday !== 'undefined' && options.bynmonthday.length ){
            console.log('Inside LastDayOfMonth');
            monthlyOccurenceDay = 'Last Day of Month';
        }
        return monthlyOccurenceDay;
    }

    //check mobile devices
    export function isMobileDevice() {
        if (navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)) {
            return true
         } else {
            return false
         }
    }

// convert image to base64 from file url
export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        if (validateBase64(base64data)) {
            resolve(base64data);
        } else {
            resolve("");
        }
      }
    });
  }

export const validateBase64 = function(base64Str) {
    try { 
        const list = base64Str.split(';base64,');
        if(list[0] && list[1] && list[0].split('image/')[0] && list[0].split('image/')[1] && list[0].split(':')[0] && list[0].split(':')[1]) {
            return true;
        } else {
            false;
        }
    }
    catch(e){ 
        return false; 
    }
}

export function dataURItoBlob(dataURI) {
    // var arr = dataURI.split(','), mime = arr[0].match(/:(.*?);/)[1];
    // return new Blob([atob(arr[1])], {type:mime});
    
    // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {type: mimeString});
  return blob;

}

export function getFibonacciBackoff(count = 0, initialDelay = 1000, maximum_backoff = 30000) {
    let delay1 = initialDelay, delay2 = initialDelay, delay = 0;
    for (let i = 0; i < count; i++) {
        delay = delay1 + delay2;
        delay1 = delay2;
        delay2 = delay;
    }
    return Math.min(delay, maximum_backoff);
}

export function retryableRequestError(error) {
    function isRetryableError(error) {
        if (!error.config) {
          // Cannot determine if the request can be retried
          return false;
        }
        const responseBody = (error && error.response && error.response.data) || {};
        let isAPICustomError = false;
        if(typeof responseBody === "object"){
            isAPICustomError = ("errorCode" in responseBody) && ("errorId" in responseBody);
        }
        return axiosRetry.isRetryableError(error) && !isAPICustomError;
    }
    return axiosRetry.isNetworkError(error) || isRetryableError(error);
}

export function getRetryDelay(retryCount) {
    const delay =  getFibonacciBackoff(retryCount);
    console.log('Retrying request : ', retryCount , ` wait ${delay} milliseconds`);
    return delay;
}

export const saveRetrylogs = async(retryLogData, retryCount, pushKey) => { 
    const idToken = localStorage.getItem("idToken")
    const appName = () => {
        if (process.env.REACT_APP === 'ObjectEditor') {
            return "Object Editor App"
        } else if (process.env.REACT_APP === 'Lifesherpa') {
            return "Lifesherpa Registration App"
        } else {
            return "Autism@work Registration App"
        }
       
    }
    const url = `/api-retry-log`
    const apiURL = `${retryLogData.config.baseURL.replace(config.serverURL, "")}${retryLogData.config.url}`
    const data = {
        "apiURL": apiURL,
        "x-cloud-trace-context": retryLogData.response.headers["x-cloud-trace-context"],
        "function-execution-id": retryLogData.response.headers["function-execution-id"],
        "response": retryLogData.response.data,
        "httpStatusCode": retryLogData.response.status,
        "attemptNumber": retryCount,
        "appName": appName(),
        "attemptKey": pushKey,
        "timestamp": moment().format('YYYY-MM-DD HH:mm:ss Z') //"2021-03-22 19:13:31 +05:30"
    }
    console.log("save retry log data: ", data)
    await sherpaAPI.post(url, data, {headers: {'Authorization': `Bearer ${idToken}`}})
}

export function onRetry(retryCount, error, requestConfig){
    let pushKey = ""
    if (requestConfig.headers["pushKey"]) {
        pushKey = requestConfig.headers["pushKey"]
    } else {
        pushKey = randomString(20)
    }

    saveRetrylogs(error, retryCount, pushKey)
    requestConfig.headers["pushKey"] = pushKey
    console.log("onRetry: ", pushKey)
    
    return requestConfig
}

function randomString(length) {
    // eslint-disable-next-line
    var bytes = new Uint8Array(length);
    var result = [];
    var charset =
      '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';

    var cryptoObj =
      window.crypto || window.msCrypto;
    if (!cryptoObj) {
      return null;
    }

    var random = cryptoObj.getRandomValues(bytes);

    for (var a = 0; a < random.length; a++) {
      result.push(charset[random[a] % charset.length]);
    }

    return result.join('');
  }

export function detectPlatform() {
    const userAgent = navigator.userAgent.toLowerCase();
    let platform = "Unknown"
    if (/android/.test(userAgent)) {
        platform = 'Android';
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
        platform = 'iOS';
    } else if (/windows phone/.test(userAgent)) {
        platform = 'Windows Phone';
    } else if (/mac/.test(userAgent)) {
        platform = 'Mac';
    } else if (/windows/.test(userAgent)) {
        platform = 'Windows';
    } else if (/linux/.test(userAgent)) {
        platform = 'Linux';
    } else {
        platform = 'Unknown';
    }
    console.log("Device Platform: ", platform)
    return platform
}
    
    